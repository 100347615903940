//Modal tarifa

#reglas-tarifa{
    margin: 20px;
    height: 200px;
    border: solid thin #1099ad;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
}

.addRegla{
    margin-left:20px;
}

#productos-lista{
    height: 500px;
    border: solid thin #1099ad;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
}

.cabecera-items{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.registro-items{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
}

.input-item-id, .input-item-nombre, .input-item-iva, .input-item-cantidad, .input-item-pvp, .input-item-descuento, .input-item-importe, .input-item-hasta{
    width:95%;
}

.modal-tarifa-footer-botones{
    width:100%;
    display: flex;
    justify-content: space-between;
}
.modal-tarifa-footer-botones div{
    width:50%;
}

.cabecera-item-regla-orden{
    width:10%;
}
.cabecera-item-regla-base{
    width:40%;
}
.cabecera-item-regla-tipo{
    width:10%;
}
.cabecera-item-regla-cantidad{
    width:20%;
}

.cabecera-item-regla-borrar{
    width:5%;
}

.registro-item-regla-orden{
    width:10%;
}
.registro-item-regla-base{
    width:40%;
}
.registro-item-regla-tipo{
    width:10%;
}
.registro-item-regla-cantidad{
    width:20%;
}
.registro-item-regla-borrar{
    display:flex;
    justify-content:space-around; 
    width:5%;
}
#resultado{
    text-align: center;
    margin-bottom: 1.5rem;
}
