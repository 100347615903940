//Home

.home-container .card-header{
    background-color: #E8F8F5;
    color: #1099ad;
}

.home-container .card a{
    text-decoration: none;
    color: black;
}
.home-container .card a:hover{
    color: #1099ad;
}
.card:hover{
    cursor: pointer;
}

.top-productos,.productos-vendidos, .top-clientes{
    width: 100%;
    height: 600px;
    background-color: #fff;
    border-radius: 10px;
    border: solid thin silver;
    font-size: 13px;
}
.top-clientes{
    width: 100%;
    height: 245px;
    background-color: #fff;
    border-radius: 10px;
    border: solid thin silver;
    font-size: 13px;
}
.linea-lista{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.productos-vendidos,.top-productos{
    overflow-y: scroll;
}

.list-home-posicion{
    width:10%;             
}
.list-home-sku{
    width:15%;
}
.list-home-nombre{
    width:35%;
}
.list-home-precio{
    width:10%;
}
.list-home-oferta{
    width:10%;
}
.list-home-stock{
    width:10%;
}
.list-home-vendidos{
    width:10%;
}

.linea-listcliente{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.listclient-home-posicion{
    width:10%;             
}
.listclient-home-id{
    width:15%;
}
.listclient-home-nombre{
    width:60%;
}
.listclient-home-pedidos{
    width:15%;
}


.charts{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    margin-top:1rem; 
    justify-content:space-between;
}

.chart-container{
    width: 49%;
}

.card-mini{
    width: 18%;
    margin-bottom: 1.5rem;
}