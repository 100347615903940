@media (max-width: 480px) {

    #productos-container{
        justify-content: center !important;
    }

    #productos-container .card {
        margin: auto;
        margin-top: 2rem;
    }

    .h-screen{
        height: 100%;
    }
}