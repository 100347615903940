#productos-lista{
    border-radius: 10px;
}

.registro-lista, .cabecera-lista{
    border-bottom: solid thin #1099ad;
    background-color: #fff;
    padding: .3rem;
    display: flex;

}

.cabecera-lista{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position:sticky; 
    top:0; 
    border-top:line solid thin;
    display: flex;
    width: 100%;
}