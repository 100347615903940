//Forms
.custom-form{
    background: linear-gradient(-45deg, #00adff,#3edf8d);
    border-radius: 20px;
    border: solid thin #ff0084;
    color:#FFF;    
}

.form-group{
    margin-bottom: .5rem;
}

//Multiselects
/*
.choices__item{
    background-color: #E8F8F5 !important;
    border: solid thin #1099ad !important;
    color: #1099ad !important;
}
*/
.choices__item--selectable{
    padding-right: 0 !important;
}
.choices__item--selectable::after{
    display: none;
}
.choices__list--dropdown{
    z-index: 5 !important;
}