.tooltipp{
    position: relative;
}

.tooltipp:hover .tooltip-box{
    display: inline-block;
}

.tooltip-box{
    display: none;
    width: max-content;
    position: absolute;
    background-color: black;
    line-height: 20px;
    z-index: 500;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    left: 0;
    top: 0;
}

.tooltip-box::after{
    content: "";
    display: block;
    border-top: 7px solid black;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    bottom: -7px;
    left: calc(50% - 7px);
}