//Avisos
.aviso-rojo{
    background-color: red;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    text-align: right;
    margin-right: 0.4rem;
    font-size: smaller;
}

.aviso-verde{
    background-color: green;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: smaller;
}

.aviso-azul{
    background-color: lightseagreen;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: smaller;
}


.aviso-morado{
    background-color: #DA8CFE;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: smaller;
}
