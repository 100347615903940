@media screen and (max-width: 799px) {


    .h-screen {
        height: 100%;
    }

    //Barra auxiliar    
    #acciones{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #acciones a {
        margin-bottom: 1.5rem;
    }

    

}    

