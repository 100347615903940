@media screen and (max-width: 799px) {
    //Modales
    .producto-mobile-media-basico{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    
    .producto-mobile-basico{
        width: 100% !important;
    }

    .producto-mobile-sku-estado-visibilidad{
        display: block !important;
    }

    .producto-mobile-sku-estado-visibilidad div{
        width: 100% !important;
    }

    .producto-mobile-tasa-precio-precioespecial-stock{
        flex-wrap: wrap;
    }

    .producto-mobile-tasa-precio-precioespecial-stock div{
        width: 45% !important
    }


    #carouselImagenesProductos{
        margin: auto !important;
    }

    .producto-mobile-set select, .producto-mobile-set label{
        width: 50% !important;
    }
    
    .atributo-contenedor{
        width:45%;
        margin-bottom: 3rem !important;
    }
}

