//Etiquetas
.etiquetas{
    border: #1099ad solid thin;
    background-color:#E8F8F5;
    color: #1099ad;
    border-radius: 10px;
    padding: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.etiquetas i{
    color: #1099ad;
    margin: auto;
    padding-left: 1rem;
}
.etiquetas i:hover, .etiquetas p:hover{
    cursor: pointer;
}