//Arbol categorias
ul{
    list-style: none;
}

#tree-categories{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #1099ad;
    padding: 10px;
    overflow-y: scroll;
    width:35%;
    height: 600px;
    color: #E8F8F5;
}

#details-categories{
    background-color: #E8F8F5;
    border-top-right-radius: 15px;   
    border-bottom-right-radius: 15px;
    color: #1099ad;
}

#tree-categories p{
    margin-bottom: 0.2rem;
}

.item-level1:hover{
    cursor: pointer;
}

.item-level2{
    display: none;
}
.item-level2:hover{
    cursor: pointer;
    display: flex;
}

.item-level3{
    display: none;
}
.item-level3:hover{
    cursor: pointer;
}

.item-level4{
    display: none;
}

.item-level5{
    display: none;
}