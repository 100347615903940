//Tablas
.custom-table{
    background-color: #E8F8F5;
    //background: linear-gradient(-45deg, #343c44,#575d65 ,#575d65, #557956, #575d65, #575d65, #343c44  );
    border:solid thin  #1099ad;// #ff0084;   
    border-radius: 10px;
    cursor:pointer;
    width: 100%;
    color:#1099ad;
}

.custom-table thead{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #1099ad;
    color: #E8F8F5;
}



.custom-table tbody tr {
    border-top:solid thin #1099ad;
}
.custom-table tbody tr:hover {
    background: linear-gradient(-45deg, #9FE0FF,#B7F3D4);
    border-top:solid thin #1099ad;
}
.custom-table td, .custom-table th {
    padding: .5rem;
}
.pagination{
    margin-top: 1rem;
}