//Cards
#clientes-container{
    margin-top: 2rem;
    justify-content: space-between;
}

#clientes-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border:solid thin #1099ad;
}

.card:hover{
    cursor: pointer;
}

#clientes-container .card-title{
    min-height: 50px;
}

#clientes-container .card-text{
    margin-bottom:0.3rem !important;
}

//Tags
.tag-tienda-magento{
    background-color: #D66320;
    color: #fff;
    width: fit-content;
    padding: 0.2rem;
    text-align: right;
    margin-right: 0.4rem;
    font-size: smaller;
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    width: 100%;
    text-align: center;
    margin-right: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}


#pedidos{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: solid thin silver;
    margin-bottom: 2rem;
    font-size: 13px;
    height: 270px;
    overflow-y: scroll;
}

.cabecera-pedidos{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.registro-pedidos{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}