@media screen and (max-width: 799px) {


    .card-mini{
        width:45% !important;
        margin-bottom: 1.5rem;
    }


    .charts-mobile{
        flex-direction:column;
    }

    .chart-container{
        width: 100% !important;
    }

    .top-productos,.productos-vendidos{
        overflow-x: scroll;
    }
    .linea-lista{
        width:600px;
    }
    .list-home-posicion{
        width:30px;             
    }
    .list-home-sku{
        width:40px;
    }
    .list-home-nombre{
        width:150px;
    }
    .list-home-precio{
        width:50px;
    }
    .list-home-oferta{
        width:50px;
    }
    .list-home-stock{
        display:30px;
    }
    .list-home-vendidos{
        display:30px;
    }
}    

