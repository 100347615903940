//Cards
#productos-container{
    margin-top: 2rem;
}

#productos-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border:solid thin #1099ad;
}

#productos-container .card-title{
    height: 100px;
}

#productos-container .card-img-top{
    height: 200px;
}

.card:hover{
    cursor: pointer;
}

.botonidioma{
    border: #1099ad solid thin;
    border-radius: 100px;
    margin-right: 10px;
    color: #1099ad;
}

.botonidioma-seleccionado{
    background-color: #1099ad;
    color: #ffffff;
}

.idioma-noseleccionado{
    display: none !important;
}

.idioma-seleccionado{
    display: block !important;
}