//Cards
#pedidos-container{
    margin-top: 2rem;
    justify-content: space-between;
}

#pedidos-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border:solid thin #1099ad;
}

.card:hover{
    cursor: pointer;
}

#pedidos-container .card-title{
    min-height: 50px;
}

#pedidos-container .card-text{
    margin-bottom:0.3rem !important;
}

.lista-productos{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: solid thin silver;
    margin-bottom: 2rem;
    font-size: 13px;
    height: 270px;
    overflow-y: scroll;
}

//Tags
.tag-status{
    background-color: orange;
    color: #fff;
    width: fit-content;
    padding: 0.2rem;
    text-align: right;
    margin-right: 0.4rem;
    font-size: smaller;
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 0;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 5px;
}

.tag-tienda-magento{
    background-color: #D66320;
    color: #fff;
    width: fit-content;
    padding: 0.2rem;
    text-align: right;
    margin-right: 0.4rem;
    font-size: smaller;
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    width: 100%;
    text-align: center;
    margin-right: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.processing{
    background-color: red;
}
.complete{
    background-color: green;
}
