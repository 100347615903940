main{
    min-height: 85vh;
}
//Barra navegacion

.navbar,footer{
    background-color: #E8F8F5;//#1099ad;
}

footer{
    color:#1099ad;
}

.navbar-aux{
    background-color: transparent !important;
}

.nav-link{
    color: #1099ad;
    border-radius: 10px;
}

.nav-link:hover{
    background-color: #1099ad;
    color: #E8F8F5;
}

.navbar-brand img {
    height:70px;
    width:245px;
    object-fit:cover
}

.container-fluid{
    padding-left: 0;
}


//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}



//Botones
.btn-success{
    background-color: #1099ad !important;
    color: #E8F8F5 !important;
}

//Scrollbar style
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
margin-right: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}

//Errores
.cajaError{
    background-color: #E8F8F5;
    color: #1099ad;
    width: fit-content;
    border: solid thin #1099ad;
    border-radius: 0.5rem;
    padding: 0.3rem;
    display: flex;
    flex-wrap: wrap;
    margin: .7rem;
    font-size: smaller;
    align-items: center;
}

.hide-error{
    cursor: pointer;
}

